import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    const params = (new URL(document.location)).searchParams
    const scrollToParam = params.get('scroll_to')

    if (!scrollToParam) return

    const domElement = document.querySelector(`#${scrollToParam}`)

    if (!domElement) return

    const options = {}
    options.behavior = params.get('scroll_to_behavior') || 'smooth'
    options.block = params.get('scroll_to_block') || 'center'
    options.inline = params.get('scroll_to_inline') || 'nearest'

    domElement.focus()

    if (document.hasFocus() && domElement.value) {
      const contentLength = domElement.value.length
      domElement.setSelectionRange(contentLength, contentLength)
      this.resizeInput(domElement)
    }

    domElement.scrollIntoView(options)
  }

  // TODO: animate resize to make it nicer for the user ?
  resizeInput (input) {
    input.style.height = "auto"

    setTimeout(() => { input.style.height = (25 + input.scrollHeight) + "px" }, 100)
  }
}
