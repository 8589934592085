import VanillaModal from 'vanilla-modal'

export default class {
  constructor () {
    const modalDOMId = '.modal-template'

    const modal = new VanillaModal({
      modal: modalDOMId,
      modalInner: '.modal-template__inner',
      modalContent: '.modal-template__content',
      clickOutside: false
    })

    // Attach the vanilla modal instance to its DOM element and dispatch a custom event
    document.querySelector(modalDOMId).modal = modal
    document.dispatchEvent(new CustomEvent('modal-ready', { detail: { modal } }))
  }
}
