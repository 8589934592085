import RemoteRails from "stimulus-remote-rails"
import VanillaModal from 'vanilla-modal'

export default class extends RemoteRails {
  connect () {
    this.modal = new VanillaModal({
      modal: '.modal-template',
      modalInner: '.modal-template__inner',
      modalContent: '.modal-template__content'
    })
  }

  replace (event) {
    super.replace(event)

    if (event.originalTarget.attributes.action.value === '/mon-compte/favoris') {
      this.modal.open('#modal-favorite-notifications')
    }
  }

  closeModal (event) {
    // QUICKFIX for some reason the modal.close() function refuses to work
    // This WILL BREAK if the modal template is changed and the close button is not directly before the event button
    event.originalTarget.previousElementSibling.click()
  }
}
