// Affiche le message flash pendant 3 secondes.
//  - content : html, ou texte si type renseigné

export default class {
  // constructeur
  constructor (content, type) {
    // classes html de l'icone de fermeture
    // classes pour l'admin et l'applocation
    this.iconClose = "far fa-times"

    // element flash message
    this.$element = $('[data-is-flash-message]')
    // fermeture du flash
    this.selectorClose = 'data-is-flash-close'

    // récupération content & type
    this.content = content

    if ((type !== null) && (type !== undefined)) {
      this.type = type
    } else {
      this.type = ''
    }

    // ### Appels initialisation & événements ####
    this.init()
    this.bindEvents()
  }

  // initialisation du flash message
  // construction du message + apparition
  init () {
    const self = this
    const flashContent = `<div class="alert alert-${self.type}"> ${self.content} <a class="flash-close" ${self.selectorClose}=""><i class="${self.iconClose}"></i></a></div>`
    return this.$element.removeClass('show').clearQueue().html(flashContent).delay(200).queue(function () {
      $(this).addClass('show')
      $(this).dequeue()
      $(this).delay(5000).queue(function () {
        $(this).removeClass('show')
        return $(this).dequeue()
      })
    })
  }
  // https://stackoverflow.com/questions/14447635/jquery-addclass-then-removing-a-class

  // événements
  bindEvents () {
    const self = this

    // fermeture du flash message au click sur la croix
    return $(`[${self.selectorClose}]`).on('click', () => self.$element.clearQueue().removeClass('show'))
  }
}

// Handle flash message when requests are in ajax
$(document).on('ajax:success ajax:error triggerFlashMessage', (event) => {
  const [, , xhr] = event.detail
  const data = JSON.parse(xhr.getResponseHeader('X-Message'))
  if (data) {
    const message = decodeURIComponent(escape(data.message))
    new FlashMessage(message, data.type)
  }
})
