import { Autocomplete } from 'stimulus-autocomplete'

export default class extends Autocomplete {
  onInputBlur () {
    if (this.mouseDown) return
    this.resultsTarget.hidden = true
    if (!this.hiddenTarget.value) this.inputTarget.value = ''
  }

  commit (selected) {
    if (selected.getAttribute('aria-disabled') === 'true') return

    if (selected instanceof HTMLAnchorElement) {
      selected.click()
      this.resultsTarget.hidden = true
      return
    }

    const textValue = selected.firstElementChild ? selected.firstElementChild.textContent.trim() : selected.textContent.trim()
    const value = selected.getAttribute('data-autocomplete-value') || textValue
    this.inputTarget.value = textValue

    if (this.hasHiddenTarget) {
      this.hiddenTarget.value = value
    } else {
      this.inputTarget.value = value
    }

    this.inputTarget.focus()
    this.hideAndRemoveOptions()

    this.element.dispatchEvent(new CustomEvent('autocomplete.change', {
      bubbles: true,
      detail: { value: value, textValue: textValue }
    }))
  }

  fetchResults () {
    const query = this.inputTarget.value.trim()
    if (!query || query.length < this.minLength) {
      this.hideAndRemoveOptions()
      return
    }

    if (!this.hasUrlValue) return

    const url = new URL(this.urlValue, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    params.append(this.searchKey, query)
    url.search = params.toString()

    const headers = { 'X-Requested-With': 'XMLHttpRequest', Accept: this.contentType }

    this.element.dispatchEvent(new CustomEvent('loadstart'))

    fetch(url.toString(), { headers })
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
        this.identifyOptions()
        const hasResults = !!this.resultsTarget.querySelector('[role="option"]')
        this.resultsTarget.hidden = !hasResults
        this.element.dispatchEvent(new CustomEvent('load'))
        this.element.dispatchEvent(new CustomEvent('loadend'))
      })
      .catch(() => {
        this.element.dispatchEvent(new CustomEvent('error'))
        this.element.dispatchEvent(new CustomEvent('loadend'))
      })
  }

  get searchKey () {
    return this.data.get('search') || 'q'
  }

  get contentType () {
    return this.data.get('content-type') || 'text/x.autocomplete+html'
  }
}
