/* eslint no-console:0 */

// MODULES =====================================================================
import Rails from 'rails-ujs'

// Fonts
import "typeface-montserrat"

// tailwind base contain reset css
import "stylesheets/tailwind-base.sass"
import "stylesheets/application.sass"
// components and utils should be over librairies
import "stylesheets/tailwind-components.sass"
import "dropzone/dist/dropzone.css"

import ImageObjectFit from "@utils/image-object-fit"
import Modal from "@utils/modal"
import ConstantHeight from "@utils/constant-height"
import FlashMessage from "@utils/flash-messages"
import executeRecaptcha from "@utils/grecaptcha"

// Stimulus
import "@controllers"

if (process.env.NODE_ENV === "development") {
  import("stylesheets/development.sass")
}

// CONFIGURATION ===============================================================
Rails.start()

window.FlashMessage = FlashMessage
window.Rails = Rails
window.executeRecaptcha = executeRecaptcha

document.addEventListener("DOMContentLoaded", () => {
  new ImageObjectFit()
  new Modal()
  new ConstantHeight()
})
