import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    document.addEventListener("modal-ready", (event) => {
      this.modal = event.detail.modal
      this.modal.open('#modal-profile-complete')
    })
  }

  closeModal () {
    this.modal.close('#modal-profile-complete')
  }
}
