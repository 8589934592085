import Dropzone from "dropzone"
import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = ["input", "persistedFile"]

  initialize () {
    Dropzone.autoDiscover = false // necessary quirk for Dropzone error in console
    this.dropZone = createDropZone(this)
    this.hideFileInput()
    this.bindEvents()
    this.displayPersistedFile()
  }

  // Private
  hideFileInput () {
    this.inputTarget.disabled = true
    this.inputTarget.style.display = "none"
  }

  bindEvents () {
    this.dropZone.on("addedfile", file => {
      setTimeout(() => {
        const dropNewUploadController = new DirectUploadController(this, file)
        file.accepted && dropNewUploadController.start()
      }, 500)
    })

    this.dropZone.on("removedfile", file => {
      file.controller && file.controller.hiddenInput.remove()

      // Remove attachment's signed_id from its hidden input to destroy it when updating the post
      if (file.attachmentInput) file.attachmentInput.value = ''
    })

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort()
    })
  }

  displayPersistedFile () {
    this.persistedFileTargets.forEach(target => {
      const fileData = target.dataset.attachment
      const { url, size, name } = JSON.parse(fileData)
      const mockFile = { name: name, size: size, attachmentInput: target }
      const callback = () => this.dropZone.emit("complete", mockFile)

      this.dropZone.displayExistingFile(mockFile, url, callback, 'anonymous', true)
    })
    document.querySelectorAll('.dz-progress').forEach(progressBar => { progressBar.style.display = 'none' })
    this.dropZone.options.maxFiles = this.dropZone.options.maxFiles - this.persistedFileTargets.length
  }

  get headers () {
    const metaAttr = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
    return { "X-CSRF-Token": metaAttr }
  }

  get url () {
    return this.inputTarget.getAttribute("data-direct-upload-url")
  }

  get maxFiles () {
    return this.data.get("maxFiles") || 1
  }

  get maxFileSize () {
    return this.data.get("maxFileSize") || 256
  }

  get acceptedFiles () {
    return this.data.get("acceptedFiles")
  }

  get addRemoveLinks () {
    return this.data.get("addRemoveLinks") || true
  }
}

class DirectUploadController {
  constructor (source, file) {
    this.directUpload = new DirectUpload(file, source.url, this)
    this.source = source
    this.file = file
  }

  start () {
    this.file.controller = this
    this.hiddenInput = this.createHiddenInput()
    this.directUpload.create((error, attributes) => {
      if (error) {
        this.hiddenInput.remove()
        this.emitDropzoneError(error)
      } else {
        this.hiddenInput.value = attributes.signed_id
        this.emitDropzoneSuccess()
      }
    })
  }

  createHiddenInput () {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = this.source.inputTarget.name
    return this.source.inputTarget.parentElement.appendChild(input)
  }

  directUploadWillStoreFileWithXHR (xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent (xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    )
  }

  uploadRequestDidProgress (event) {
    const progress = (event.loaded / event.total) * 100
      this.file.previewTemplate.querySelector(
      ".dz-upload"
    ).style.width = `${progress}%`
  }

  emitDropzoneUploading () {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit("processing", this.file)
  }

  emitDropzoneError (error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit("error", this.file, error)
    this.source.dropZone.emit("complete", this.file)
  }

  emitDropzoneSuccess () {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit("success", this.file)
    this.source.dropZone.emit("complete", this.file)
  }
}

function createDropZone (controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false
  })
}
