import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'postBody',
    'moderatorCheckbox',
    'modal'
  ]

  connect () {
    this.modalReadyCallback = (event) => {
      this.modal = event.detail.modal
    }
    document.addEventListener("modal-ready", this.modalReadyCallback)

    if (this.hasPostBodyTarget) this.postBodyHeight = this.postBodyTarget.offsetHeight
  }

  disconnect () {
    document.removeEventListener('search_controller.accommodationSelected', this.modalReadyCallback)
  }

  insertQuote (event) {
    event.preventDefault()

    let url = event.currentTarget.dataset.quotePostUrl

    if (!url) {
      console.warn(`[stimulus-posts-controller] The post's url is missing.`)
      return
    }

    if (!this.postBodyTarget) return

    if (this.hasModeratorCheckboxTarget && this.moderatorCheckboxTarget.checked) {
      const urlObject = new URL(url)
      urlObject.searchParams.append("moderator", "true")
      url = urlObject.toString()
    }

    fetch(url)
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(parsedResponse => { this.postBodyTarget.value = parsedResponse.body })
      .then(this.resizePostBody())
  }

  // TODO: animate resize to make it nicer for the user ?
  resizePostBody () {
    this.postBodyTarget.style.height = "auto"

    setTimeout(() => { this.postBodyTarget.style.height = (25 + this.postBodyTarget.scrollHeight) + "px" }, 100)
  }

  showModal (event) {
    event.stopPropagation()
    const [, , xhr] = event.detail
    this.modalTarget.innerHTML = xhr.response
    this.modal.open(`#${this.modalTarget.id}`)
  }

  closeModal (event) {
    event.stopPropagation()
    document.dispatchEvent(new CustomEvent('triggerFlashMessage', event))
    this.modal.close(`#${this.modalTarget.id}`)

    const postID = event.target.dataset.postId

    if (!postID) return

    const postDOMElement = document.querySelector(`#${postID}`)

    if (!postDOMElement) return

    const [, , xhr] = event.detail
    postDOMElement.outerHTML = xhr.response
  }
}
