import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["unhide"]

  password (event) {
    if (this.unhideTarget.type === "password") {
      event.target.classList.remove('fa-eye-slash')
      event.target.classList.add('fa-eye')
      this.unhideTarget.type = "text"
    } else {
      event.target.classList.remove('fa-eye')
      event.target.classList.add('fa-eye-slash')
      this.unhideTarget.type = "password"
    }
  }
}
