import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    delay: Number,
    url: String
  }

  connect () {
    if (!sessionStorage.getItem('welcome-modal-closed')) {
      document.addEventListener("modal-ready", (event) => {
        setTimeout(() => {
          this.modal = event.detail.modal
          this.modal.open('#welcome-modal')
          this.modal.dom.modal.classList.add("home")
          this.incrementOpenNumber()
        }, this.delayValue * 1000)
      })
    }
  }

  incrementOpenNumber () {
    const csrfToken = document.getElementsByName("csrf-token")[0].content
    fetch(this.urlValue,
      {
        method: 'PATCH',
        headers: { "X-CSRF-Token": csrfToken },
        Accept: "application/json"
      }
    )
      .then(response => console.log(response))
      .catch(error => console.log(error))
  }

  closeModal () {
    this.modal.close('#welcome-modal')
    sessionStorage.setItem('welcome-modal-closed', true)
  }
}
