import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'parent'
  ]

  connect () {
    this.parentTargets.forEach(parent => {
      if (this.hasChildren(parent.value)) {
        const allChildrenChecked = this.allChildrenChecked(parent.value)
        parent.checked = allChildrenChecked
        parent.indeterminate = this.someChildrenChecked(parent.value) && !allChildrenChecked
      }
    })
  }

  toggleChildren (event) {
    const parentElement = event.currentTarget
    document.querySelectorAll(`[data-parent-id="${parentElement.value}"]`).forEach(element => {
      if (!element.disabled) {
        element.checked = parentElement.checked
        element.dispatchEvent(new Event('input'))
      }
    })
  }

  allChildrenChecked (parentId) {
    const parentChildren = this.parentChildren(parentId)
    return parentChildren.length > 0 ? parentChildren.every(child => child.checked) : false
  }

  someChildrenChecked (parentId) {
    const parentChildren = this.parentChildren(parentId)
    return parentChildren.length > 0 ? parentChildren.some(child => child.checked) : false
  }

  hasChildren (parentId) {
    return this.parentChildren(parentId).length > 0
  }

  parentChildren (parentId) {
    return [...document.querySelectorAll(`[data-parent-id="${parentId}"]`)]
  }
}
