import RemoteRails from "stimulus-remote-rails"

export default class extends RemoteRails {
  followRedirect (event) {
    const [,, xhr] = event.detail

    const location = xhr.getResponseHeader('location')
    if (location) {
      window.location = location
    }
  }

  replace (event) {
    // Forward event and listen for it in 'flash-messages.js' as it may contain a message to display to the user
    // We need to do that before calling 'super.replace(event)' as the latter stops the event's propagation
    document.dispatchEvent(new CustomEvent('triggerFlashMessage', event))

    super.replace(event)
  }
}
