import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    behavior: String,
    block: String,
    inline: String
  }

  connect () {
    this.options = {
      behavior: this.behaviorValue || 'auto',
      block: this.blockValue || 'center',
      inline: this.inlineValue || 'nearest'
    }
  }

  scrollAndFocus (event) {
    event.preventDefault()
    const domElement = document.querySelector(event.currentTarget.hash)
    if (domElement) {
      domElement.scrollIntoView(this.options)
      domElement.focus()
    }
  }
}
