import Lightbox from "stimulus-lightbox"

export default class extends Lightbox {
  get defaultOptions () {
    return {
      thumbnail: false,
      animateThumb: false,
      showThumbByDefault: false,
      download: false,
      selector: '.item'
    }
  }
}
